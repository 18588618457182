<template>
  <div class="wrap">
    <Navbar :title="$t('page.teamReport')" :border="false" :fixed="true" />
    <div class="content">
      <p class="date_label">{{ $t("teamReport.from") }}</p>
      <div class="input" @click="showFromPicker = true">{{ $format(fromValue, "yy-MM-dd") }}</div>
      <p class="date_label">{{ $t("teamReport.to") }}</p>
      <div class="input" @click="showToPicker = true">{{ $format(toValue, "yy-MM-dd") }}</div>
      <div class="quick_wrap">
        <!-- <p class="date_label">{{ $t('teamReport.quickSelect') }}:</p> -->
        <div class="btns_wrap">
          <Button class="btn_item" v-for="item, index in dateSelect" :key="`date${index}`"
            @click="selectDate(item.value)">{{
                $t(`memberDetails.btn.${item.t}`)
            }}</Button>
        </div>
      </div>
    </div>
    <div class="content" style="border: none">
      <div class="group_balance_title">{{ $t('teamReport.groupBalance') }}</div>
      <div class="group_balance_value">{{ data.balance || 0 }}</div>
      <div class="table" v-for="item, index in tableData" :key="`table_item_${index}`">
        <div class="label">{{ $t(`teamReport.label.${item.label}`) }}</div>
        <div class="value">{{ data[item.value] }}</div>
      </div>
    </div>
    <!-- 开始时间选择 -->
    <Popup v-model="showFromPicker" position="bottom">
      <DatetimePicker v-model="fromValue" type="date" title="" @confirm="(value) => dateSelectChange('from', value)"
        @cancel="showFromPicker = false" :confirm-button-text="$t('My.Button.Two')"
        :cancel-button-text="$t('My.Button.One')" />
    </Popup>
    <!-- 结束时间选择 -->
    <Popup v-model="showToPicker" position="bottom">
      <DatetimePicker v-model="toValue" type="date" title="" @confirm="(value) => dateSelectChange('to', value)"
        :min-date="fromValue" @cancel="showToPicker = false" :confirm-button-text="$t('My.Button.Two')"
        :cancel-button-text="$t('My.Button.One')" />
    </Popup>
  </div>
</template>

<script>
import Navbar from '@/components/JNav'
import $format from "@/utils/dateFormat"
import { post } from "@/utils/newRequest"
import { DatetimePicker, Popup, Button } from 'vant'
import quickDateCycle from "@/utils/quickSelectDate"
export default {
  components: { Navbar, DatetimePicker, Popup, Button },
  data() {
    this.dateSelect = [
      { t: "today", value: quickDateCycle("today") },
      { t: "yesterday", value: quickDateCycle("yesterday") },
      { t: "thisMonth", value: quickDateCycle("thisMonth") },
      { t: "lastMonth", value: quickDateCycle("lastMonth") }
    ],
      this.tableData = [
        { label: "membersNum", value: 'child_num' },
        { label: "rechargeAmount", value: 'pay_money' },
        { label: "withdrawAmount", value: 'extract_money' },
        { label: "betNum", value: 'bet_users' },
        { label: "betAmount", value: 'bet_money' },
        { label: "winNum", value: 'win_users' },
        { label: "winAmount", value: 'win_money' },
        { label: "commission", value: 'ref_money' },
      ]
    return {
      fromValue: new Date(),
      toValue: new Date(),
      showFromPicker: false,
      showToPicker: false,
      data: {}
    }
  },
  created() {
    // console.log(quickDateCycle("lastMonth"))
    this.getData();
  },
  methods: {
    fromDateSelectConfirm(value) {
      this.showFromPicker = false
      if (value == this.fromValue) {
        return
      }
      this.fromValue = value
      console.log(value)
    },
    dateSelectChange(which, value) {
      if (which == 'from') {
        this.showFromPicker = false
      } else {
        this.showToPicker = false
      }
      // if (value == this[`${which}Value`]) {
      //   return
      // }
      this[`${which}Value`] = value;
      this.getData();
    },
    selectDate(item) {
      this.fromValue = new Date(item[0]);
      this.toValue = new Date(item[1])
      this.getData();
    },
    async getData() {
      try {
        const res = await post("/users/ref/userinfo",
          { act: "group", start_day: $format(this.fromValue, "yyyy-MM-dd"), end_day: $format(this.toValue, "yyyy-MM-dd") });
        this.data = res
        console.log(res)
      } catch (error) {
        console.log(error)
        if (error.msg) this.$toast(error.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 0 20px;
  border-bottom: 1px solid #ccc;
  @backgroundCom: #CCD0E1;

  .date_label {
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0;
  }

  .input {
    padding: 10px 18px;
    border-radius: 6px;
    border: 1px solid #333;
  }

  .quick_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    .btns_wrap {
      display: flex;
      align-items: center;

      .btn_item {
        margin: 5px;
        padding: 7px 12px;
        border-radius: 8px;
        color: var(--theme);
        border: 1px solid var(--theme);
        font-size: 12px;
        height: fit-content;
        background: @backgroundCom;

        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
    }
  }

  @groupBalanceHeigt: calc(44rem / 16);

  .group_balance_title {
    margin-top: 32px;
    border-radius: 10px;
    line-height: @groupBalanceHeigt;
    text-align: center;
    background: var(--theme);
    color: var(--textColor);
    font-size: 14px;
    font-weight: 700;
  }

  .group_balance_value {
    line-height: calc(@groupBalanceHeigt - 2px);
    border-radius: 10px;
    border: 1px solid var(--theme);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 18px;
  }

  .table {
    display: flex;
    @gap: 10px;
    margin-bottom: @gap;
    // flex-wrap: wrap;

    .childCom {
      padding: 10px;
      // height: 38px;
      width: calc((100% - @gap) / 2);
      border-radius: 10px;
      border: 1px solid var(--theme);
    }

    .label {
      padding: 10px;
      line-height: 1.3;
      font-weight: bold;
      background: @backgroundCom;
      color: var(--theme);
      .childCom();
    }

    .value {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      color: #333;
      .childCom();
      margin-left: @gap;
    }
  }
}
</style>
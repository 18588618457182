import $format from "@/utils/dateFormat"
const getDateNum = (month, year, strict = false) => {
  if (!strict) {
    month += 1;
  }
  const more = [1, 3, 5, 7, 8, 10, 12];
  const less = [4, 6, 9, 11]
  if (more.indexOf(month) !== -1) {
    return 31
  } else if (less.indexOf(month) !== -1) {
    return 30
  } else {
    if (!!year) {
      if ((year % 4 === 0 && year % 100 !== 0) || (year % 100 === 0 && year % 400 === 0)) {
        return 29
      }
    }
    return 28
  }
}
export default (cycle) => {
  const nowDate = new Date();
  const year = nowDate.getFullYear();
  const month = nowDate.getMonth() + 1;
  const date = nowDate.getDate();
  const day = nowDate.getDay();
  const oneDay = 24 * 60 * 60 * 1000;
  const oneMonth = (M = month) => {
    return oneDay * getDateNum(M)
  };
  const now = nowDate.getTime();
  const yesterday = now - oneDay;
  const lastMonth = month - 1 > 0 ? month - 1 : 12;
  const lastYear = lastMonth == 12 ? year - 1 : year;
  const lastMonthNum = getDateNum(lastMonth, lastYear, true);
  const lastMonthTime = now - oneMonth(lastMonth);
  switch (cycle) {
    case "today":
      return [$format(now, "yy-MM-dd"), $format(now, "yy-MM-dd")]
    case "yesterday":
      return [$format(yesterday, "yy-MM-dd"), $format(yesterday, "yy-MM-dd")]
    case "thisMonth":
      return [`${year}-${month}-1`, $format(now, "yy-MM-dd")]
    case "lastMonth":
      return [`${$format(lastMonthTime, "yy-MM")}-1`, `${$format(lastMonthTime, "yy-MM")}-${lastMonthNum}`]
    default:
      break;
  }
}